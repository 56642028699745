.navbar{
    width: 100%;
    height: auto;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 25px;
}
.leftMenu{
    display: flex;
}

.leftMenu p {
    margin-top: 25px;
    margin-bottom: 1rem;
    font-size: 30px;
}

.navbar .leftMenu .logo{
    width: 100px; 
    height: 100px;
}

/* .navbar .leftMenu img{
    width: 100%;
    height: 100%;
} */

.navbar .rightMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar .rightMenu h5{
    margin-left: 20px;
}
/* 
.navbar .rightMenu .no-of-products {
    background: #f57224;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 4px;
    width: 15px;
    height: 20px;
    border-radius: 3px;
    position: absolute;
    top: 30px;
    right: 23em;
    -- position: absolute;
    top: -5px;
    left: 60px;
    display: flex;
    justify-content: center;
    align-items: center; --
} */

.logout-btn {
    padding: 5px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    border: hidden;
    background-color: #2eca8b;
    margin-left: 10px;
}

.navbar .rightMenu .navlinks{
    font-size: 12px;
    color: #000;
    font-weight: 600;
    margin: auto 10px;
}

.site-title{
    font-family: cursive;
    font-size: 35px;
    color: #000;
    text-decoration: none;
}

.title {
    text-decoration: none;
  }
  