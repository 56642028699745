.cart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
}

.cart-container .cart-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

@media(max-width: 768px) {
    .cart-container .cart-card {
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #e4e4e4;
    }
}

.cart-container .cart-card .cart-img {
    width: 150px;
    height: 120px;
}

@media(max-width: 768px) {
    .cart-container .cart-card .cart-img {
        width: 90%;
        height: auto;
    }
}

.cart-container .cart-card .cart-img img {
    width: 100%;
    height: 100%
}

.cart-container .cart-card .cart-name,
.cart-container .cart-card .cart-price {
    width: 150px;
    margin-left: 20px;
}

@media(max-width: 768px) {

    .cart-container .cart-card .cart-name,
    .cart-container .cart-card .cart-price {
        width: 90%;
        margin-left: 0px;
        text-align: center;
        margin-top: 4px;
    }
}

.cart-container .cart-card .cart-price-orignal {
    width: 150px;
    margin-left: 20px;
    color: #f57224;
}

@media(max-width: 768px) {

    .cart-container .cart-card .cart-price-orignal {
        width: 90%;
        margin-left: 0px;
        text-align: center;
        margin-top: 4px;
    }
}

.delete-btn {
    background-color: transparent;
    border: hidden;
    outline: none;
    cursor: pointer;
    color: red;
}

.inc,
.dec {
    cursor: pointer;
}

@media(max-width: 768px) {
    .delete-btn {
        margin-top: 4px;
    }

    .inc,
    .dec {
        margin-top: 4px;
    }
}

/* cart summary */
.cart-summary {
    display: flex;
    flex-direction: column;
    padding: 50px;
    border: 1px solid #e4e4e4;
    width: 300px;
}

.cart-summary .cart-summary-heading {
    border-bottom: 1px solid #f57224;
}

.cart-summary .cart-summary-price {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.cashout-link {
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: #000;
}

/* cashout */
.success-msg {
    background-color: green;
    color: #fff;
    padding: 7px;
    font-size: 12px;
    font-weight: 600;
}

.success-msg .home-link {
    color: #fff;
}