.card {
    flex-direction: row;
    max-width: 30em;
  }

.card img {
    max-width: 25%;
    margin: auto;
    padding: 0.5em;
    border-radius: 0.7em;
  }