/* products */

h1 {
    padding-left: 35px;
    border-bottom: 1px solid #e4e4e4;
}

@media(max-width: 768px) {
    h1 {
        text-align: center;
        padding: 10px;
    }
}

.products-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
}

.products-container .product-card {
    width: 300px;
    height: auto;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    box-shadow: 5px 5px 4px #e4e4e4;
    position: relative;
}

/* .products-container .product-card .product-img {
    width: 100%;
    height: 200px;
} */

.products-container .product-card .product-img img {
    width: 100%;
    height: 100%;
}

.products-container .product-card .product-name {
    display: block;
    width: 100%;
    color:#df3333;
    margin-top: 5px;
}

.products-container .product-card .product-price {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    gap: 7px;
}

.crossed-Price{
    text-decoration: line-through;
}

.products-container .product-card .addCart-btn {
    background-color: #2395f1;
    color: #fff;
    border: hidden;
    cursor: pointer;
    width: 100%;
    padding: 10px 14px;
    margin-top: 5px;
    margin-bottom: 2px;
    outline: none;
}